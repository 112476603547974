<template>
    <dashboard-layout>
        <v-card class="mt-4">
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ gig.title }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'artist.gigs.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field label="venue" v-if="gig.venue" v-model="gig.venue.name"
                                          disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="starts at" v-model="gig.starts_at" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="ends at" v-model="gig.ends_at" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="created at" v-model="gig.created_at" disabled></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </dashboard-layout>
</template>

<script>
import Gig from "@/models/Gig";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "gigs-show",
    components: {DashboardLayout},
    data: function () {
        return {
            gig: {}
        }
    },
    async mounted() {
        const gigId = this.$route.params.id
        this.gig = await Gig.include('venue').find(gigId)
    },
}
</script>

<style scoped>

</style>
